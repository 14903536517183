import { IpadContainer } from "./common/IpadContainer"
import { IphoneContainer } from "./common/IphoneContainer"
import { PrimaryColor, SecondaryColor } from "./Themes"

export const HomeView = () => {
    return (
        <div>
            <div style={{
                padding: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: `linear-gradient(to bottom, ${PrimaryColor.color2}, #ffffff)`
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 20,
                    justifyContent: "center",
                    alignItems: "center"
                }}>

                <text style={{
                    fontSize: 50,
                    fontWeight: "bolder",
                }}>
                    {"技术创造美好"}
                </text>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 20,
                    justifyContent: "center",
                    alignItems: "center"
                }}>

                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"本公司专注于技术与软件研发"}
                </text>
                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"公司目前研发有一款用于学习的APP软件"}
                </text>
                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"从视频和PDF截图并遮挡知识点生成正反卡片"}
                </text>
                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"复习卡片时可以跳转到视频与PDF的相应位置"}
                </text>
                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"基于深度学习的FSRS定期复习算法让复习更高效"}
                </text>
                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"用树苗的生长可视化对知识点的熟练度"}
                </text>
                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"支持电脑，安卓，iOS，自建多端笔记同步云服务"}
                </text>
                <text style={{
                    fontSize: 15,
                    margin: 5,
                }}>
                    {"随时随地单手持握手机就能进行学习与卡片制作"}
                </text>
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: "#fff",
                alignItems: "center",
                justifyContent: "center",
                background: `linear-gradient(to top, ${PrimaryColor.color2}, #ffffff)`
            }}>
                <div style={{
                    flex: 1
                }} />
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20
                }}>
                    <IphoneContainer height={600}>
                        <img
                            src={require("../assets/iphone_screenshot/1.jpg")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </IphoneContainer>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20
                }}>
                    <IpadContainer height={600}>
                        <img
                            src={require("../assets/ipad_screenshots/1.png")}
                            style={{
                                height: "100%",
                                width: "100%"
                            }}
                        />
                    </IpadContainer>
                </div>
                <div style={{ flex: 1 }} />
            </div>
        </div>
    )
}
