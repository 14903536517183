import { Link, RouterProvider, createBrowserRouter, useNavigation } from "react-router-dom";
import { HomeView } from "./HomeView";
import { DownloadView } from "./DownloadView";
import { AboutView } from "./AboutView";
import { ContractView } from "./ContractView";
import { useState } from "react";
import { PrimaryColor } from "./Themes";
import { WebRoutes } from "./ScreenView";


export interface BaseViewProps  {
    children: JSX.Element
}


export const BaseView = (props: BaseViewProps) => {
    const [routelink, setRoutelink] = useState(WebRoutes[0].path);
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#fff",
            minHeight: "95vh"
        }}>
            <div style={{
                height: 60,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                border: "solid",
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderBottomWidth: 1,
                borderBottomColor: PrimaryColor.color4
            }}>
                <div style={{
                    width: "20%",
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: "flex",
                    paddingBottom: "0.1vh",
                    paddingTop: "0.1vh",
                }}>
                    <div style={{
                        height: 40,
                        width: 40,
                        marginRight: "1vw",
                    }}>
                        <img
                            style={{
                                height: "100%",
                                width: "auto",
                                borderRadius: "20%",
                                boxShadow: "2px 2px 2px #888888"
                            }}
                            src={require("../assets/logo.png")}
                        />
                    </div>
                    <text
                        style={{
                            fontWeight: "bold",
                            color: PrimaryColor.color4,
                            fontSize: "min(2vh, 1.2vw)",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        欧拉星空智能科技有限公司
                    </text>
                </div>
                <div style={{
                    flex: 1
                }}>
                </div>
                <div style={{
                    width: "40vw",
                    display: "flex",
                }}>
                    {
                        WebRoutes.map((v, i) => {
                            return (
                                <div style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                                    key={v.path}
                                >
                                    <Link 
                                        to={v.path}
                                        onClick={() => setRoutelink(v.path)}
                                        style={{
                                            textDecoration: "none"
                                        }}
                                    >
                                   <text style={{
                                        fontSize: "1vw",
                                        fontWeight: "bold",
                                        color: v.path === routelink? PrimaryColor.color4: "#000"
                                    }}
                                    >
                                        {v.display}
                                    </text>
                                    </Link>
                                </div>

                            )
                        })
                    }
                </div>
            </div>
            <div>
                {props.children}
            </div>
        </div>
    )
}
