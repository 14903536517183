import AppStoreIcon from "./common/AppStoreIcon"
import AndroidIcon from "./common/AndroidIcon"
import WindowsIcon from "./common/WindowsIcon"
import { PrimaryColor } from "./Themes"

export const DownloadView = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#fff",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <div style={{
                height: "80vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div style={{
                    margin: 20,
                    height: "30vh",
                    flex: 1,
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <AppStoreIcon
                        height="10vh"
                        width="10vh"
                    />
                    <a 
                        href="javascript:void(0)"
                        style={{
                            textDecoration: "none"
                        }}
                    >
                        <div style={{
                            margin: 20,
                            border: "none",
                            borderRadius: 10,
                            backgroundColor: PrimaryColor.color2,
                            padding: 10,
                        }}>
                            前往应用商店
                        </div>
                    </a>
                    <text style={{
                        fontWeight: "bold",
                    }}>
                        可在苹果应用商店下载
                    </text>
                </div>
                <div style={{
                    height: "30vh",
                    flex: 1,
                    margin: 20,
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <AndroidIcon
                        height="10vh"
                        width="10vh"
                    />
                    <a 
                        href="javascript:void(0)"
                        style={{
                            textDecoration: "none"
                        }}
                    >
                        <div style={{
                            margin: 20,
                            border: "none",
                            borderRadius: 10,
                            backgroundColor: PrimaryColor.color2,
                            padding: 10,
                        }}>
                            下载APK
                        </div>
                    </a>
                    <text style={{
                        fontWeight: "bold",
                    }}>
                        可在各大应用商店下载
                    </text>
                </div>
                <div style={{
                    height: "30vh",
                    margin: 20,
                    flex: 1,
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <WindowsIcon
                        height="10vh"
                        width="10vh"
                    />
                    <a 
                        href="javascript:void(0)"
                        style={{
                            textDecoration: "none"
                        }}
                    >
                        <div style={{
                            margin: 20,
                            border: "none",
                            borderRadius: 10,
                            backgroundColor: PrimaryColor.color2,
                            padding: 10,
                        }}>
                            下载EXE
                        </div>
                    </a>
                    <text style={{
                        fontWeight: "bold",
                    }}>
                       功能完善中 
                    </text>
                </div>
            </div>
        </div>
    )
}