import {
    BrowserRouter,
    createBrowserRouter,
    Route,
    Router,
    RouterProvider,
    Routes,
    useNavigation
} from "react-router-dom";
import { HomeView } from "./HomeView";
import { useState } from "react";
import { PrimaryColor } from "./Themes";
import { DownloadView } from "./DownloadView";
import { AboutView } from "./AboutView";
import { ContractView } from "./ContractView";
import { BaseView } from "./BaseView";
import { UsageView } from "./UsageView";
import { PrivacyView } from "./PrivacyView";
import { PreviewView } from "./AppPreviewView";
import Redirect from "./common/Redirect";

export interface OptionRoute {
    display: string,
    path: string,
    element: JSX.Element
}

export const WebRoutes: OptionRoute[] = [
        {
            display: "首页",
            path: "/",
            element: <HomeView/>
        },
        {
            display: "APP预览",
            path: "/preview",
            element: <PreviewView/>
        },
        {
            display: "下载",
            path: "/download",
            element: <DownloadView/>
        },
        {
            display: "笔记森林",
            path: "/ankiforest",
            element: <Redirect/>
        },
        {
            display: "关于我们",
            path: "/about",
            element: <AboutView/>
        },
        {
            display: "条款与协议",
            path: "/contract",
            element: <ContractView/>
        },
    ]


const router = createBrowserRouter(WebRoutes);


export const ScreenView = () => {
    return (
        <BrowserRouter>
            <BaseView>
                <Routes>
                {
                    WebRoutes.map((v, i) => (
                        <Route
                            path={v.path}
                            element={v.element}
                            key={i}
                        />
                    ))
                }
                <Route
                    path={"/terms-of-use"}
                    element={<UsageView/>}
                />
                <Route
                    path={"/privacy-policy"}
                    element={<PrivacyView/>}
                />
                </Routes>
            </BaseView>
            <div style={{
                height: 40,
                display: "flex",
                flexDirection: 'row',
                justifyContent: "center",
                alignItems: "center"
            }}>
                {"2024-2025北京欧拉星空智能科技有限公司版权所有  京ICP备2024061259号-1A"}
            </div>
        </BrowserRouter>
    )
}
